@use 'styles/variables' as var;
@use 'styles/functions' as fn;

$spacing: 8;
$maximum-site-content-width: 1440px;
$maximum-site-content-width-narrow: 1280px;
$default-gutter-width: fn.spacing(8);
$medium-and-up-gutter-width: fn.spacing(4);

.footer {
  background-color: var.$color-blue-50;
  color: var.$color-blue-800;
  padding-top: fn.spacing(4);

  a {
    color: var.$color-blue-800;
  }

  @media screen and (min-width: var.$breakpoint-lg) {
    padding-top: fn.spacing(8);
  }

  &[data-show-nav='false'] {
    padding-top: 0;
  }
}

.footer-content-container {
  max-width: $maximum-site-content-width;
  padding-left: $default-gutter-width;
  padding-right: $default-gutter-width;

  &[data-narrow='true'] {
    max-width: $maximum-site-content-width-narrow;
  }

  @media screen and (min-width: var.$breakpoint-md) {
    padding-left: $medium-and-up-gutter-width;
    padding-right: $medium-and-up-gutter-width;
  }
}

.footer-row {
  display: flex;
  flex-direction: column;
  padding-bottom: fn.spacing(4);
  border-bottom: 1px solid var.$color-neutral-150;

  @media screen and (min-width: var.$breakpoint-lg) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: fn.spacing(2);
  }
}

.footer-logo {
  display: block;
  height: 40px;
  position: relative;
  width: 40px;

  img {
    position: absolute;
    inset: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  @media screen and (min-width: var.$breakpoint-lg) {
    width: 50px;
  }
}

.footer-brand-icon-row {
  margin-bottom: fn.spacing(2);
  flex-direction: initial;

  @media screen and (min-width: var.$breakpoint-lg) {
    flex-direction: row-reverse;
  }
}

.footer-section {
  flex: 1;
  margin-top: fn.spacing(4);

  @media screen and (min-width: var.$breakpoint-lg) {
    margin-top: 0;
    margin-left: fn.spacing(2);
  }

  &:first-child {
    max-width: none;

    @media screen and (min-width: var.$breakpoint-lg) {
      max-width: 30%;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    margin-top: fn.spacing(1);

    a {
      color: var.$color-neutral-975;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  h4 {
    color: var.$color-neutral-300;
  }
}

%footer-section-placeholder {
  flex: 1;
  margin-top: fn.spacing(4);

  @media screen and (min-width: var.$breakpoint-lg) {
    margin-top: 0;
    margin-left: fn.spacing(2);
  }

  &:first-child {
    max-width: none;

    @media screen and (min-width: var.$breakpoint-lg) {
      max-width: 30%;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    margin-top: fn.spacing(1);

    a {
      color: var.$color-neutral-975;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  h4 {
    color: var.$color-neutral-300;
  }
}

.footer-section-logo {
  @extend %footer-section-placeholder;

  padding-top: fn.spacing(4);
  border-top: 1px solid var.$color-neutral-150;
  flex: 2;

  @media screen and (min-width: var.$breakpoint-lg) {
    padding-top: 0;
    border-top: 0;
    max-width: fn.spacing(42);
  }
}

.text-copy {
  text-align: left;
  color: var.$color-blue-800;

  @media screen and (min-width: var.$breakpoint-lg) {
    text-align: right;
  }
}

.footer-disclosure {
  color: var.$color-blue-800;
  margin-top: fn.spacing(6);

  p {
    line-height: 1.5;

    &:not(:last-child) {
      margin-bottom: fn.spacing(2);
    }
  }

  ol {
    padding-inline-start: fn.spacing(2);

    li {
      margin-bottom: fn.spacing(3);
    }
  }

  li::marker {
    font-size: 11px;
  }

  @media screen and (min-width: var.$breakpoint-lg) {
    margin-top: fn.spacing(6);
  }
}

.footer-copyright-row {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: fn.spacing(4);
  padding: fn.spacing(4) 0;
  flex-direction: column-reverse;

  @media screen and (min-width: var.$breakpoint-lg) {
    flex-direction: row;
  }
}

.footer-copyright {
  color: var.$color-blue-800;
}

.footer-social-links-row {
  margin-top: fn.spacing(2);

  @media screen and (min-width: var.$breakpoint-md) {
    margin-bottom: 0;
  }
}

.footer-social-links-container {
  flex-direction: column-reverse;
  align-items: right;

  @media screen and (min-width: var.$breakpoint-lg) {
    flex-direction: row;
    align-items: center;
  }
}

.scroll-to-top {
  @media screen and (min-width: var.$breakpoint-lg) {
    display: none !important;
  }
}

.markers-grid {
  margin-top: fn.spacing(2);

  @media screen and (min-width: var.$breakpoint-lg) {
    justify-content: end;
  }
}

.footer-link {
  &:hover {
    color: var.$color-blue-300;
  }
}

.markers-grid-item {
  max-width: fn.spacing(10);
  overflow: hidden;
}

.footer[data-site='achieve'] {
  .footer-logo {
    width: 66px;
    height: 54px;
    overflow: hidden;

    img {
      width: 215px;
      min-width: inherit;
      max-width: inherit;
    }
  }
}

.footer[data-site='website-fdr'] {
  .footer-logo {
    width: 110px;
    height: 60px;
  }
}