@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.footer-brand-icon-row {
  margin-bottom: fn.spacing(6);
}

.social-icon {
  display: block;
  position: relative;
  height: fn.spacing(3);
  width: fn.spacing(3);
}

.footer-social-icon {
  min-height: 24px;
  min-width: 25px;
  display: flex;
}

.icon-container {
  display: flex;
  min-height: 24px;
  min-width: 24px;
}

.icon-gray {
  opacity: 1;
  position: absolute;

  &:hover {
    opacity: 0;
  }
}

.icon-color {
  opacity: 0;
  position: absolute;

  &:hover {
    opacity: 1;
  }
}

.footer-social-icons-container {
  justify-content: flex-start;

  @media (min-width: var.$breakpoint-lg) {
    justify-content: flex-end;
  }
}